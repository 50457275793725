<template>
    <div>
        <img class="banner" src="https://seetop-1257860468.file.myqcloud.com/html/img/bind-top.png">
        <div id="app" class="register-wrapper">
            <ul>
                <li>
                    <div class="register-input-wrapper">
                        <div class="register-input-label">手机号</div>
                        <input class="register-input" v-model="mobile" placeholder="请输入手机号" readonly ref="mobile" @click="clickMobile">
                    </div>
                    <div class="register-code-button" @click="sendCode">{{codeText}}</div>
                </li>
                <p id="note"></p>
                <li>
                    <div class="register-input-wrapper">
                        <div class="register-input-label">验证码</div>
                        <input class="register-input" v-model="code" placeholder="请输入验证码" readonly @click="clickCode">
                    </div>
                </li>
            </ul>
            <div class="register-button" @click="bind">登录</div>
        </div>
        <!--数组键盘-->
        <van-number-keyboard
                :show="showKeyBoard"
                @blur="showKeyBoard = false"
                @input="onInput"
                @delete="onDelete"
        />
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import { getLocalStorage } from '../../utils/localStorage'

  export default {
    name: 'Bind',
    data () {
      return {
        showKeyBoard: false,
        mobile: '',
        code: '',
        codeText: '获取验证码',
        currentInput: 'mobile',
        send: false,
        beforeUrl: '',
        channel: 'seetop'
      }
    },
    props: [],
    mixins: [globalConfig],
    methods: {
      onInput (value) {
        if (this.currentInput === 'mobile') {
          if (this.mobile.length >= 11) {
            return
          }
          this.mobile = this.mobile + value
        } else if (this.currentInput === 'code') {
          if (this.code.length >= 6) {
            return
          }
          this.code = this.code + value
        }
      },
      onDelete () {
        if (this.currentInput === 'mobile') {
          this.mobile = this.mobile.substring(0, this.mobile.length - 1)
        } else if (this.currentInput === 'code') {
          this.code = this.code.substring(0, this.code.length - 1)
        }
      },
      clickMobile () {
        this.showKeyBoard = true
        this.currentInput = 'mobile'
        // this.$refs.mobile.$el.style.bor
        // console.log(this.$refs.mobile.focus())
      },
      clickCode () {
        this.showKeyBoard = true
        this.currentInput = 'code'
      },
      // 发送验证码
      sendCode () {
        if (this.mobile) {} else {
          this.$toast('请输入手机号码')
          return
        }
        if (!this.send) {} else {
          this.$toast('验证码已发送，请稍候再试')
          return
        }
        this.axios.post(this.apiUrl + 'mall/sms/sendBindCode', {
          mobile: this.mobile
        }).then((response) => {
          const data = response.data
          if (data.status) {
            this.$toast('发送成功')
            this.countDown()
          } else {
            this.$toast(data.msg)
          }
        })
      },
      // 倒计时
      countDown () {
        if (this.send === true) {
          return
        }
        this.codeText = '60s'
        this.send = true
        const that = this
        var seconds = 60
        const timer = setInterval(function () {
          seconds--
          that.codeText = seconds + 's'
          if (seconds === 1) {
            clearInterval(timer)
            that.codeText = '重新获取'
            that.send = false
          }
        }, 1000)
      },
      // 绑定
      bind () {
        const that = this
        if (this.mobile && this.mobile.length === 11) {} else {
          this.$toast('请输入正确手机号')
          return
        }
        if (this.code && this.code.length === 6) {} else {
          this.$toast('请输入6位验证码')
          return
        }
        this.axios.post(this.apiUrl + 'mall/sms/checkBindCode', {
          mobile: this.mobile,
          captcha: this.code,
          channel: this.channel
        }).then((response) => {
          const data = response.data
          if (data.status) {
            this.$toast({
              message: '登录成功',
              onClose () {
                window.location.href = that.beforeUrl
              }
            })
          } else {
            this.$toast(data.msg)
          }
        })
      },
      // 是否绑定
      isBind () {
        this.axios.get(this.apiUrl + 'mall/init/bind').then((response) => {
          const data = response.data
          if (data.user) {
            window.location.href = this.beforeUrl
          }
        })
      },
      getBeforeUrl () {
        const url = document.location.toString()
        const arr = url.split('url')
        if (arr.length >= 2) {
          return arr[1].substr(1)
        } else {
          return this.webUrl + '#/user'
        }
      }
    },
    mounted () {
      // 确定分销来源
      const cookieChannel = getLocalStorage('srcChannel')
      if (cookieChannel) {
        this.channel = cookieChannel
      }
      console.log(document.cookie)
      console.log('锁粉目标：' + cookieChannel)
      // 确定来源页面
      this.beforeUrl = this.getBeforeUrl()
      // 已绑定则跳转
      this.isBind()
      this.beforeUrl = decodeURIComponent(this.beforeUrl)
      console.log('跳转链接' + this.beforeUrl)
    }
  }
</script>

<style scoped>
    .banner{
        width: 100vw
    }
    .register-wrapper{
        background: white;
        padding: 0.2rem 0.6rem;
    }
    .register-wrapper ul li:nth-child(5) .register-input-wrapper{
        border-right: 1px solid #edecf1;
    }
    .register-wrapper ul li{
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        -webkit-align-items: center;
        -webkit-justify-content: space-between;
        padding: 1.2rem 1rem;
        border-bottom: 1px solid #f2f1f6;
    }
    .register-input{
        font-size: 1rem;
        padding: 0.2rem;
        outline-style: none;
        border-style: none;
        color: #555555;
        width: 70%;
    }
    .register-input-wrapper{
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        font-size: 1rem;
        width: 70%;
    }
    input::-webkit-input-placeholder {
        color: #b6b4c2;
        font-size: 1rem;
    }
    input:-moz-placeholder {
        color: #b6b4c2;
        font-size: 1rem;
    }
    input::-moz-placeholder {color: #b6b4c2;
        font-size: 1rem;
    }
    input:-ms-input-placeholder {
        color: #b6b4c2;
        font-size: 1rem;
    }
    .register-input-label{
        margin-right: 1rem;
        color: #292929;
    }
    .register-button{
        font-size: 1rem;
        color: white;
        border-radius: 0.3rem;
        width: 80%;
        margin: 4rem auto;
        text-align: center;
        padding: 0.5rem 0 0.4rem;
        letter-spacing: 0.1rem;
        background-image: linear-gradient(to bottom right, #fa1f1f, #fc3f00);
    }
    .register-code-button{
        color: #62606e;
        width: 30%;
        text-align: center;
        font-size: 1rem;
    }
    #note{
        color: red;
        font-size: 0.8rem;
        padding-left: 1rem;
    }
    .input-active {
        /*border: 0.01rem solid #eeeeee;*/
    }
</style>
